import { injectable } from "inversify"

import * as Api from "../constants/ApiUrlConstants"
import container from "../wires/Bootstrapper"
import SERVICE_IDENTIFIER from "../wires/Identifiers"

import { IApiService } from "./shared/ApiService"

import { ModelBase } from "../model/BaseModels"
import { TraceAccessModel } from "../model/TraceModels"

export interface ITraceService {
    TraceAccess(): Promise<ModelBase>
}

@injectable()
export class TraceService implements ITraceService {
    apiService: IApiService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
    }

    async TraceAccess(): Promise<ModelBase> {
        let model: TraceAccessModel = {
            Platform: "Web",
            Model: window.navigator.userAgent,
        }
        return await this.apiService.postAsync<ModelBase>(Api.TraceAccess, model)
    }
}