export const PostMail = '/Authentication/PostMail'
export const CheckPassword = '/Authentication/CheckPassword'
export const RequestPassword = '/Authentication/RequestPassword'
export const Logout = '/Authentication/Logout'

export const GetCategorieDocumenti = '/Documenti/GetCategorieDocumenti'
export const GetDocumenti = '/Documenti/GetDocumenti'
export const GetDocumentiAssicurativi = '/Documenti/GetDocumentiAssicurativi'
export const UploadFiles = '/Documenti/UploadFiles'
export const UploadNewFiles = '/Documenti/UploadNewFiles'
export const DeleteFile = '/Documenti/DeleteFile'
export const InviaDocumenti = '/Documenti/InviaDocumenti'
export const GetDocumentiPnv = '/Documenti/GetDocumentiPnv'

export const GetPratiche = '/Pratica/GetPratiche'
export const GetPratica = '/Pratica/GetPratica'
export const GetReferente = '/Pratica/GetReferente'
export const GetPraticaTimeline = '/Pratica/GetPraticaTimeline'
export const GetDocumentoCode = '/Pratica/GetDocumentoCode'
export const GetPnvDocumentoCode = '/Pratica/GetPnvDocumentoCode'

export const GetFile = '/api/FileProvider/GetFile'
export const GetFilePreview = '/api/FileProvider/GetFilePreview'

export const TraceAccess = '/Trace/TraceAccess'