import * as React from 'react'
import { Table, Button, Collapse, Upload, Result, Modal} from 'antd'
import { RcCustomRequestOptions } from 'antd/lib/upload/interface'
import { RouteComponentProps, withRouter } from 'react-router'

import { IDocumentiService } from '../../services/DocumentiService'
import IoCContainer from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'

import { AllDocType, DocumentiInput, DocumentiResponseModel, DocumentiUploadInput, Documento, DocumentoDeleteInput, DocumentState, TipoDocumento } from '../../model/DocumentiModels'
import { CaricaDocumentiRouteParams } from './CaricaDocumentiRouteParams'

import { NavigationBar } from '../NavigationBar'
import successIcon from './../../content/icon_status.svg'
import infoIcon from './../../content/info_grey.svg'

import './caricadocumenti.less'
import { INotifyService } from '../../services/shared/NotifyService'

import info from './../../content/info_grey.svg'

const { Panel } = Collapse
const { Column } = Table

const iconInviatoStyle = {
    width: '40px', height: '40px'
}

export class GroupedItem {
    key: string
    value: Array<Documento>
}
const { confirm } = Modal;

class PageCaricaDocumentiCmp extends React.Component<RouteComponentProps<CaricaDocumentiRouteParams>, DocumentiResponseModel> {

    private documentiService: IDocumentiService
    private tipoDoc: TipoDocumento
    private notifyService: INotifyService


    getStatoDescription = (stato: DocumentState): string => {
        switch (stato) {
            case DocumentState.Invalido: return 'Da Caricare'
            case DocumentState.Downloadabile: return 'Scaricabile'
            case DocumentState.DaCaricare: return 'Da Caricare'
            case DocumentState.Caricato: return 'Caricato'
            case DocumentState.DaVerificare: return 'In verifica'
            case DocumentState.Verificato: return 'Verificato'
            case DocumentState.Uploadato: return 'Inviato'
            default: return ""
        }
    }

    uploadImage = async (options: RcCustomRequestOptions, iddoc: string) => {
        const { file } = options

        var data = new DocumentiUploadInput()
        data.idDocumento = iddoc
        data.files = [file]
        data.idPratica = this.props.match.params.id
        data.tipodocumento = TipoDocumento[this.tipoDoc]



        var resp = await this.documentiService.UploadFiles(data)
        if (resp.IsValid === true)
            this.setState(resp)
        //else {
        //    console.log("display error uploadImage")
        //    this.notifyService.errorWithNotifies('ATTENZIONE',resp.Notifies)
        //}
    };

    deleteImage = async (iddoc: string) => {

        var data = new DocumentoDeleteInput()
        data.idDocumento = iddoc
        data.IdPratica = this.props.match.params.id
        data.TipoDocumento = this.tipoDoc

        var resp = await this.documentiService.DeleteFile(data)
        if (resp.IsValid === true)
            this.setState(resp)
    };

    groupBy = (list, keyGetter) => {
        const map = new Map()
        list.forEach((item) => {
            const key = keyGetter(item)
            const collection = map.get(key)
            if (!collection) {
                map.set(key, [item])
            } else {
                collection.push(item)
            }
        })
        return map
    }

    inviaDoc = async () => {
        var data = new DocumentiInput()
        data.idPratica = this.props.match.params.id
        data.TipoDocumento = TipoDocumento[this.tipoDoc]


        confirm({
            title: 'Dichiarazione di Responsabilità',
            content: <>Dichiaro di essere autorizzato a caricare i documenti richiesti anche per conto degli altri interessati alla richiesta di mutuo.<br />
                In assenza di autorizzazione la tua richiesta non potrà essere presa in carico</>,
            onOk: async () => {
                var resp = await this.documentiService.InviaDocumenti(data)
                if (resp.IsValid === true)
                    this.setState(resp)
            },
            onCancel() {
                console.log('Cancel');
            },
        });



    }


    constructor(props) {
        super(props)
        var initialstate = new DocumentiResponseModel();
        initialstate.IsLoaded = false;
        this.state = initialstate;

        this.documentiService = IoCContainer.get(SERVICE_IDENTIFIER.DOCUMENTI_SERVICE)
        this.tipoDoc = TipoDocumento[this.props.match.params.tipodocumento]
        this.notifyService = IoCContainer.get(SERVICE_IDENTIFIER.NOTIFY);
    }

    async componentDidMount() {
        let data = new DocumentiInput()
        data.idPratica = this.props.match.params.id
        data.TipoDocumento = TipoDocumento[this.tipoDoc]

        var res = await this.documentiService.GetDocumenti(data)
        if (res.IsValid === true)
            this.setState({ ...res, IsLoaded: true });
    }

    renderDescrizione = (row: Documento) => {
        if (!row.Note) return row.Descrizione
        return <>
            <p>{row.Descrizione}</p>
            <div style={{ borderLeft: '2px solid red', height: '100%', paddingLeft: '12px' }}>
                <span className="Regular14Grey700">{row.Note}</span>
            </div>
        </>
    }

    renderStatoAction = (row: Documento) => {
        switch (row.Stato) {
            case DocumentState.Invalido:
            case DocumentState.DaCaricare:
                return (
                    <Upload accept="*/*"
                        multiple
                        showUploadList={false}
                        customRequest={options => this.uploadImage(options, row.IdDocumento)}>
                        <Button type='primary' ghost shape='round'>Allega</Button>
                    </Upload>
                )
            case DocumentState.Caricato:
                return <Button type='primary' ghost shape='round' onClick={() => this.deleteImage(row.IdDocumento)}>Elimina</Button>
            default:
                return <></>
        }
    }


    renderdocs = (key: string, value: Array<Documento>) => {
        return (
            <Panel header={key} key={key}>
                <Table dataSource={value} size="middle" rowKey={it => it.IdDocumento} pagination={false}>
                    <Column title="Tipo documento" dataIndex="Descrizione" render={(_, row: Documento) => this.renderDescrizione(row)} />
                    <Column title="Stato" dataIndex="Stato" render={(_, row: Documento) => this.getStatoDescription(row.Stato)} />
                    <Column title="" dataIndex="Id" render={(_, row: Documento) => this.renderStatoAction(row)} />
                </Table>
            </Panel>
        )
    }

    onHelpClick = () => {
        Modal.info({
            width: 800,
            title: "Info",
            content: <>
                <b>L'attestato di prestazione energetica (APE)</b>  è il documento che descrive le caratteristiche energetiche di un edificio, di un'abitazione o di un appartamento.<br />
                E' uno strumento di controllo che sintetizza con una scala da A4 a G (scala di 10 lettere) le <b>prestazioni energetiche degli edifici</b> ed è obbligatorio per la vendita o l'affitto di un immobile.<br />
                L'APE ha una validità temporale massima di dieci anni a partire dal suo rilascio e deve essere aggiornato a ogni intervento di ristrutturazione o riqualificazione che modifichi la classe energetica dell'edificio o dell'unità immobiliare.<br />
                L'APE deve essere prodotto:<br />
                - <u>per costruzioni o ristrutturazioni</u> da un soggetto certificatore accreditato e iscritto nell'elenco regionale di riferimento.<br />
                Il <b>certificatore energetico</b> è solitamente un <b>tecnico abilitato alla progettazione di edifici ed impianti</b> come l'architetto, l'ingegnere ed il geometra.<br />
                L'attestato è prodotto a cura del costruttore, sia esso committente della costruzione o società di costruzione che opera direttamente.<br />
                - <u>per compravendita immobile</u> il proprietario deve rendere disponibile tale attestato al potenziale acquirente all’avvio delle trattative e consegnarlo alla fine delle medesime. <br />
                Questo significa che l’attestato deve essere disponibile già al momento della sottoscrizione del contratto preliminare.<br /><br />
                L’APE prodotto deve essere conforme al decreto interministeriale del 26 giugno 2015, basato sulle "Metodologie di calcolo delle prestazioni energetiche" e i requisiti minimi per gli edifici, come delineato nel decreto legislativo del 19 agosto 2005, n. 192 e i suoi allegati.
            </>
        })
    }

    renderAllNavigationTitle = () => {
        return <span>Allega Documenti&nbsp; &gt;&nbsp;{this.renderPageTitle()}</span>
    }

    renderPageTitle = () => {
        if (this.tipoDoc === TipoDocumento.immobile)
            return 'Immobile';
        if (this.tipoDoc === TipoDocumento.personali)
            return 'Personali';
        if (this.tipoDoc === TipoDocumento.ape)
            return this.renderAPESectionTitle();

        return null;
    }

    renderSendedSubtitle = () =>
    {
        if (this.tipoDoc === TipoDocumento.ape) return <></>;
        return <span className="Regular14Grey800">Potrai consultare l'esito della verifica in questa pagina. Ti manderemo una notifica quando l'esito sarà disponibile</span>
    }

    renderAPESectionTitle = () => {   
        return <span>APE&nbsp;<a onClick={() => this.onHelpClick()}><img src={info} style={{ width: '18px' }} /></a></span>        
    }


    render() {
        const hasDocuments = this.state.Documenti != null && this.state.Documenti.length > 0
        if (this.state.IsLoaded == false) return <></>;
        return (
            <div>
                <NavigationBar title={this.renderAllNavigationTitle()} />                
                {(hasDocuments === true)
                    ? <Collapse accordion expandIconPosition='right'>
                        {Array.from(this.groupBy(this.state.Documenti, doc => doc.CategoryTitle).keys()).map((k) =>
                            this.renderdocs(k, this.groupBy(this.state.Documenti, doc => doc.CategoryTitle).get(k)))}
                    </Collapse>
                    : <Result
                        icon={<img src={infoIcon} style={iconInviatoStyle} />}
                        title={<span className='Medium18PlacidGreen'>Nessun documento necessario per questa sezione</span>} /> 
                }
                {(this.state.StatoDocumenti === AllDocType.DaCaricare || this.state.StatoDocumenti === AllDocType.Caricati )
                    ? <Button type='primary' shape='round' disabled={this.state.StatoDocumenti != AllDocType.Caricati}
                        style={{ float: 'right', marginTop: '20px', width: '250px' }}
                        onClick={() => this.inviaDoc()}>Invia </Button>
                    : <></>}
                {(this.state.StatoDocumenti === AllDocType.Inviati && hasDocuments === true)
                    ? <Result
                        icon={<img src={successIcon} style={iconInviatoStyle} />}
                        title={<span className='Medium18PlacidGreen'>Documenti inviati con successo!</span>}
                        subTitle={this.renderSendedSubtitle()} />
                    : <></>}
            </div>
        )
    }
}

export const PageCaricaDocumenti = withRouter(PageCaricaDocumentiCmp)
