import 'antd/dist/antd.less'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import { MemoryRouter, BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker'
import it from 'antd/es/locale/it_IT'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root')


ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <ConfigProvider locale={it}>
            <App />
        </ConfigProvider>
    </BrowserRouter>,
  rootElement);

unregister()
