import * as React from 'react'
import { Table, Button, Collapse, Upload, Result, Modal} from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'

import { IDocumentiService } from '../../services/DocumentiService'
import IoCContainer from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import { PnvDocumentiModel } from '../../model/PnvDocumentiModel'

import { NavigationBar } from '../NavigationBar'
import { IPnvDocumentiService } from '../../services/PnvDocumentiService'
import moment from 'moment'
import { IPraticaService } from '../../services/PraticaService'
import { ModelBase } from '../../model/BaseModels'
import { IFileProviderService } from '../../services/FileProviderService'


import info from './../../content/info_grey.svg'

const { Column } = Table

class PnvDocumentiState {
    documenti: Array<PnvDocumentiModel>
}

let eur = Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
});

class PagePnvDocumentiCmp extends React.Component<RouteComponentProps<any>, PnvDocumentiState> {

    private pnvservice: IPnvDocumentiService;
    private praticaservice : IPraticaService;
    private fileProviderService: IFileProviderService;

    constructor(props) {
        super(props)
        let state = new PnvDocumentiState()
        state.documenti = new Array<PnvDocumentiModel>()
        this.state = state;

        this.pnvservice = IoCContainer.get(SERVICE_IDENTIFIER.PNVDOCUMENTI_SERVICE)
        this.praticaservice = IoCContainer.get(SERVICE_IDENTIFIER.PRATICA_SERVICE)
    }

    async componentDidMount() {
        debugger
        var res = await this.pnvservice.GetDocumenti(this.props.match.params.id)
        if (res.IsValid === true)
            this.setState({ documenti: res.Documenti });
    }

    async downloadDoc(doc: PnvDocumentiModel) {
        let resp = await this.praticaservice.GetPnvDocumento( doc.IdRiferimento)
    }

    onHelpClick = () => {
        Modal.info({
            width: 800,
            title: "Di seguito ti riepiloghiamo tutti i preventivi prodotti dal tuo Specialista Mutui",
            content: <>Le simulazioni della sezione sono state effettuate sulla base delle prime informazioni da Lei fornite e non costituiscono un'offerta vincolante per la Banca.<br/> Le condizioni contenute nel documento potrebbero variare in seguito alla valutazione del merito creditizio e della eventuale concessione del finanziamento.</>
        })
    }

    renderTitle = () => {
        return <span>Storico Preventivi&nbsp;<a onClick={() => this.onHelpClick()}><img src={info} style={{ width: '18px' }} /></a></span>
    }

    render() {
        return (
            <div>
                <NavigationBar title={this.renderTitle() } />

                <Table dataSource={this.state.documenti} size="middle" rowKey={it => it.IdRiferimento} pagination={false}>
                    <Column title="Data" render={(_, row: PnvDocumentiModel) => moment(row.Data).format("DD/MM/YYYY")} />
                    <Column title="Prodotto" dataIndex="Prodotto" />
                    <Column title="Durata"  render={(_, row: PnvDocumentiModel) => row.Durata} />
                    <Column title="Importo Rata" render={(_, row: PnvDocumentiModel) => eur.format(row.Rata)} />
                    <Column title="" dataIndex="" render={(_, row: PnvDocumentiModel) =>
                        <Button type='primary' ghost shape='round' onClick={() => this.downloadDoc(row)}>Download</Button>} />
                </Table>

            </div>
        )
    }
}

export const PagePnvDocumenti = withRouter(PagePnvDocumentiCmp)
