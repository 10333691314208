import axios, { AxiosInstance } from 'axios';
import SERVICE_IDENTIFIER from '../../wires/Identifiers';
import { INotifyService } from './NotifyService';
import { injectable } from "inversify";
import { IAuthService } from '../security/AuthServiceTs';
//import { IApiLoaderAction } from '../../Redux/Reducers/Actions/ApiLoaderAction';
//import { IErrorHandlerAction } from '../../Redux/Reducers/Actions/ErrorHandlerAction';
import IoCContainer from '../../wires/Bootstrapper';

export interface IApiService {
    get<T>(url: string, params: any, success: (resp: T) => void, error: (err: any) => void): void;
    getAsync<T>(url: string, params: any): Promise<T>
    getString(url: string, params: any, success: (resp: string) => void, error: (err: any) => void): void;
    post<T>(url: string, params: any, success: (resp: T) => void, error: (err: string) => void): void;
    postToken<T>(url: string, params: any, token: string, success: (resp: T) => void, error: (err: string) => void): void;
    postString(url: string, params: any, success: (resp: string) => void, error: (err: string) => void): void;
    postAsync<T>(url: string, params: any): Promise<T>
}

@injectable()
export class ApiService implements IApiService {

    client: AxiosInstance = axios.create();
    _notifyService: INotifyService;
    authService: IAuthService;
    //_store: Store<any>;
    // protected _apiLoaderAction : IApiLoaderAction;
    //_errorHandlerAction: IErrorHandlerAction

    baseurl: string = '/api'
    TITLE: string = 'Attenzione';
    isRefreshing = false;
    failedQueue = [];


    constructor() {
        this.client.interceptors.response.use(this.handleResponeSucces, this.handleResponeError);
        this.client.interceptors.request.use(this.handleRequest, this.handleRequestError);
        this._notifyService = IoCContainer.get(SERVICE_IDENTIFIER.NOTIFY);
        this.authService = IoCContainer.get(SERVICE_IDENTIFIER.AUTH_SERVICE);
        //this._store = IoCContainer.get(SERVICE_IDENTIFIER.STORE);
        //this._apiLoaderAction = IoCContainer.get(SERVICE_IDENTIFIER.API_LOADER_ACTION);
        //this._errorHandlerAction = IoCContainer.get(SERVICE_IDENTIFIER.ERROR_HANDLER_ACTION);

    }

    protected processQueue = (error, token = null) => {
        this.failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        this.failedQueue = [];
    };

    protected handleRefreshedToken = (response) => {
        var refreshToken = response.headers.refreshtoken;

        if (refreshToken == null ||
            refreshToken == undefined ||
            refreshToken == "")
            return;

        //aggiorno il token
        this.authService.setToken(refreshToken);
    }

    protected handleResponeSucces = (response) => {
        //AA gestione per l'aggiornamento del token jwt
        this.handleRefreshedToken(response);

        //this._apiLoaderAction.SetIsFinished();
        if (response.data.IsValid) {
            return response;
        }

        if (response.data.Notifies != null) {
            // NotifyType.Validation = 3
            //var validation = response.data.Notifies.filter(it => it.Type == 3)
            //if (validation.length > 0) {
            //    //response.data.FormValidation = this.notifyToValidation(validation)
            //}

            // Gestione altre notifiche
            var notifies = response.data.Notifies//.filter(it => it.Type != 3)
            if (notifies.length > 0) {
                this._notifyService.errorWithNotifies(this.TITLE, notifies)
            }
        }

        return response;
    }

    // Creazione oggetto per validazione unobtrusive delle form
    //protected notifyToValidation(notifies: Array<any>) {
    //    var obj: IFormError = {}
    //    notifies.forEach(it => {
    //        var prop = it.Property

    //        // Creo voce in obj se non presente
    //        if (!obj.hasOwnProperty(prop)) {
    //            obj[prop] = {
    //                errors: []
    //            }
    //        }

    //        // Accodo le notifiche per property specifica
    //        obj[prop].errors.push(new Error(it.Message))
    //    })

    //    return obj
    //}



    protected handleResponeError = (err) => {
        const originalRequest = err.config;
        var that = this;
        if (err.response.status === 401 && !originalRequest._retry) {
            if (this.isRefreshing) {
                return new Promise(function (resolve, reject) {
                    that.failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            this.isRefreshing = true;

            return new Promise(function (resolve, reject) {
                var token = "";
                that.client.get(that.baseurl + '/Authentication/RefreshToken', { params: { token: token } })
                    .then(({ data }) => {
                        if (!data.IsValid) {
                            that.authService.signOutAndReload()
                        }
                        //that.client.defaults.headers.common['Authorization'] = 'Bearer ' + data.AuthToken;
                        //originalRequest.headers['Authorization'] = 'Bearer ' + data.AuthToken;
                        //that.authService.setRefreshToken(data.RefreshToken)
                        that.processQueue(null, data.AuthToken);
                        resolve(axios(originalRequest));
                    })
                    .catch(err => {
                        that.processQueue(err, null);
                        
                        reject(err);
                        that.authService.signOutAndReload()
                    })
                    .then(() => {
                        that.isRefreshing = false;
                    });
            });
        }

        return Promise.reject(err);
        //const originalConfig = err.config;

        //if (err.response.status === 401 || //token invalido o scaduto
        //    err.response.status === 406) {
        //    debugger
        //    var token = this.authService.getRefresh();
        //    this.client.get(this.baseurl + '/Authentication/RefreshToken', { params: { token: token } }).then(resp => {
        //        this.authService.setToken(resp.data.Token)
        //        this.authService.setRefreshToken(resp.data.RefreshToken)
        //        this.client.defaults.headers.Authorization = `Bearer ${resp.data.Token}`; ;

        //        return this.client(originalConfig);
        //    }).catch(err => {
        //        this.authService.signOutAndReload()
        //    }
        //    );

            
        //    return Promise.reject(err)
        //}

        //if (err.response.status === 403) {
        //    this._notifyService.error('Autorizzazione Negata', err.response.data.Message)
        //    return
        //}

        //return Promise.reject(err)
    };

    protected handleRequest = (config) => {

        const token = this.authService.getToken();

        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        //this._apiLoaderAction.SetIsLoading();
        return config;
    }

    protected handleRequestError = error => {
        // Do something with request error
        //this._apiLoaderAction.SetIsFinished();
        Promise.reject(error);
    }

    public get<T>(url: string, params: any, success: (resp: T) => void, error: (err: any) => void) {

        this.client.get(this.baseurl + url, params).then(resp => {
            //let data = SerializationHelper.toInstance<T>(resp.data)
            this.handleSucces(resp, success);
        }).catch(err => {
            this.handleError(err, error);
        }
        );
    }

    private PrintStandardMessage(err: any): void {
        if (err.response.status === 401 || //token invalido o scaduto
            err.response.status === 406) {
            this._notifyService.error(this.TITLE, 'Sessione scaduta');
            return;
        }

        if (err.response.status === 413) {
            this._notifyService.error(this.TITLE, 'Alcuni file sono troppo grandi per essere caricati');
            return;
        }

        this._notifyService.error("Errore", err.message);
    }

    public async getAsync<T>(url: string, params: any): Promise<T> {
        return await this.client.get(this.baseurl + url, { params: { ...params } }).then((resp) => this.handleSucces(resp, null)).catch((err) => this.handleError(err, null));
        //let data = SerializationHelper.toInstance<T>(resp.data,type)
    }

    public getString(url: string, params: any, success: (resp: string) => void, error: (err: any) => void) {
        this.client.get(this.baseurl + url, { params: { ...params } }).then(resp => this.handleSucces(resp, success)).catch(err => {
            this.handleError(err, error);
        });
    }

    public post<T>(url: string, params: any, success: (resp: T) => void, error: (err: string) => void) {
        this.client.post(this.baseurl + url, params).then(resp => {
            // let data = SerializationHelper.toInstance<T>(resp.data)
            this.handleSucces(resp, success)
        }).catch(err => {

            this.handleError(err, error);
        });
    }

    public postToken<T>(url: string, params: any, token: string, success: (resp: T) => void, error: (err: string) => void) {

        this.client.post(this.baseurl + url, params, { headers: { Authorization: `Bearer ${token}` } }).then(resp => {
            // let data = SerializationHelper.toInstance<T>(resp.data)
            this.handleSucces(resp, success)
        }).catch(err => {

            this.handleError(err, error);
        });
    }

    public async postAsync<T>(url: string, params: any): Promise<T> {
        return await this.client.post(this.baseurl + url, params).then((resp) => this.handleSucces(resp, null)).catch((err) => this.handleError(err, null));
    }


    public postString(url: string, params: any, success: (resp: string) => void, error: (err: string) => void) {
        this.client.post(this.baseurl + url, params).then(resp => this.handleSucces(resp, success)).catch(err => {
            this.handleError(err, error);
        });
    }

    protected handleError(err, callback) {
        if (callback != null)
            callback(err);
        else
            this.PrintStandardMessage(err);
    }

    protected handleSucces(resp, success): any {

        let ret = resp;
        if (resp == null) ret = { data: { isValid: false } };
        if (success != null) {
            success(ret.data);
            return;
        }
        return ret.data;

    }
}
