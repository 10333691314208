import * as React from 'react'
import { Carousel, Col, Modal, Row } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'
import { CarouselRef } from 'antd/lib/carousel'

import { IPraticaService } from '../../services/PraticaService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { PraticaItemModel } from '../../model/PraticaModels'

import { HeaderCard } from './HeaderCard'
import { TileCard } from './TileCard'

import arrow_left from '../../content/arrow_left.svg'
import arrow_right from '../../content/arrow_right.svg'
import bollettini from '../../content/bollettini.svg'
import consultant from '../../content/consultant.svg'
import guide from '../../content/guide.svg'
import movimenti from '../../content/movimenti.svg'
import status from '../../content/status.svg'
import info_white from '../../content/info_white.svg'

class PageHomeState {
    Pratiche: Array<PraticaItemModel>
    SelectedPratica: PraticaItemModel = new PraticaItemModel()
}

class PageHomeComp extends React.Component<RouteComponentProps, PageHomeState> {

    service: IPraticaService
    carouselRef = React.createRef<CarouselRef>()

    constructor(props) {
        super(props)
        this.state = new PageHomeState()
        this.service = container.get(SERVICE_IDENTIFIER.PRATICA_SERVICE)
    }

    BackListener = () => { };

    componentDidMount() {

        this.fetchData()
    }

    componentWillUnmount() {
        window.onpageshow = null;
    }

    fetchData = async () => {
        const resp = await this.service.GetPratiche()
        if (resp.IsValid === true)
            this.setState({ Pratiche: resp.Pratiche, SelectedPratica: resp.Pratiche[0] })
    }

    onCarouselChange = (current: number) => {
        this.setState({ SelectedPratica: this.state.Pratiche[current] })
    }

    onDettaglioClick = (id: string) => {
        this.props.history.push(`/Dettaglio/${id}`)
    }

    onReferenteClick = () => {
        if (this.state.SelectedPratica.InAssegnazione === true)
            this.showInAssegnazioneDialog()
        else
            this.props.history.push(`/Referente/${this.state.SelectedPratica.Id}`)
    }

    onTimelineClick = () => {
        if (this.state.SelectedPratica.InAssegnazione === true)
            this.showInAssegnazioneDialog()
        else
            this.props.history.push(`/Timeline/${this.state.SelectedPratica.Id}`)
    }

    showInAssegnazioneDialog = () => {
        Modal.info({
            title: "Avviso",
            content: "Richiesta in lavorazione"
        })
    }

    onAllegaDocumentiClick = () => {
        this.props.history.push(`/AllegaDocumenti/${this.state.SelectedPratica.Id}`)
    }


    onPnvClick = () => {
        this.props.history.push(`/PnvDocumenti/${this.state.SelectedPratica.Id}`)
    }

    onElencoDocumentiClick = async () => {
        if (this.state.SelectedPratica.ElencoDocumentiVisible !== true) {
            Modal.info({
                title: "Avviso", // Serve per allineare correttamente il content
                content: "In questa sezione troverai i documenti da inviare al tuo Specialista Mutui per consentirci di valutare la tua richiesta di mutuo"
            })
            return
        }


        const doc = this.state.SelectedPratica.Checklist
        await this.service.GetDocumento(doc.Id, this.state.SelectedPratica.Id, doc.TipoDocumento)

        // Rimozione della eventaule notifica
        if (this.state.SelectedPratica.ElencoDocumentiHighlighted === true) {
            const index = this.state.Pratiche.findIndex(e => e.Id == this.state.SelectedPratica.Id)
            const resp = await this.service.GetPratiche()
            if (resp.IsValid === true)
                this.setState({ Pratiche: resp.Pratiche, SelectedPratica: resp.Pratiche[index] })
        }


    }

    onGuidaMutuoClick = () => {
        window.open('https://www.credem.it/content/dam/credem/documenti/Privati/Mutui/Guida_Mutuo_Credem.pdf')
    }

    renderCard = () => {
        return this.state.Pratiche.map(e => <div key={e.Id}>
                <HeaderCard id={e.Id} importo={e.Importo} durata={e.Durata} tasso={e.Tasso} onDettaglioClick={e => this.onDettaglioClick(e)} />
            </div>)
    }

    renderCarousel = () => {
        return (
            <Row align="middle">
                <Col span={2}>
                    <a onClick={() => this.carouselRef.current.prev()}><img src={arrow_left} height="35" style={{ marginBottom: "48px" }} /></a>
                </Col>
                <Col span={20}>
                    <Carousel ref={this.carouselRef} afterChange={current => this.onCarouselChange(current)} dotPosition="bottom">
                        {this.renderCard()}
                    </Carousel>
                </Col>
                <Col span={2}>
                    <a onClick={() => this.carouselRef.current.next()}><img src={arrow_right} height="35" style={{ marginBottom: "48px" }} /></a>
                </Col>
            </Row>
        )
    }

    renderAllegaDocumenti = () => {
        if (this.state.SelectedPratica.CaricaDocumentiVisible !== true) return
        return (
            <>
            <Col xl={4} xs={12}>
                <TileCard icon={bollettini} highlighted={this.state.SelectedPratica.CaricaDocumentiHighlighted} onClick={() => this.onAllegaDocumentiClick()}>Allega documenti</TileCard>
            </Col>
            <Col xl={4} xs={12}>
                <TileCard icon={movimenti} highlighted={this.state.SelectedPratica.ElencoDocumentiHighlighted} onClick={() => this.onElencoDocumentiClick()}>Elenco documenti</TileCard>
            </Col></>
        )
    }

    renderPnv = () => {
        if (this.state.SelectedPratica.ElencoPnvVisible !== true) return
        return (
            <Col xl={4} xs={12}>
                <TileCard icon={guide} highlighted={this.state.SelectedPratica.ElencoPnvHighlighted} onClick={() => this.onPnvClick()}>Storico Preventivi</TileCard>
            </Col>
        )
    }

    renderHead = () => {
        if (!this.state.Pratiche) return

        if (this.state.Pratiche.length === 1)
            return this.renderCard()
        else
            return this.renderCarousel()
    }

    gotoAlertDocumenti = () => {
        this.props.history.push(`/CaricaDoc/${this.state.SelectedPratica.Id}/${this.state.SelectedPratica.HasPendingImmobili
            ? `immobile`
            : `personali`}` 
            )
    }

    getAlertMessage = () => {
        return this.state.SelectedPratica.HasPendingImmobili
            ? "Documenti immobili pronti per l'invio"
            : "Documenti personali pronti per l'invio"
    }

    renderAlert = () => {
        debugger
        return this.state.SelectedPratica.HasPendingImmobili || this.state.SelectedPratica.HasPendingPersonali
        ?
        <Row justify="center" align="middle">
            <Col >
               <div className="rectangle-copy-14" onClick={() => this.gotoAlertDocumenti()}>
                    <img src={info_white} style={{margin:'5px 10px'}} />
                    <span className="comunicazione-sui-do">{this.getAlertMessage()}</span>
                </div>
            </Col>
        </Row>
        
        : <></>
    }
    

    render() {
        const pratica = this.state.SelectedPratica
        return (
            <>
                <Row justify="center">
                    <Col xl={10} xs={24}>
                        {this.renderHead()}
                    </Col>
                </Row>
                {this.renderAlert()}
                <Row justify="center" gutter={[20, 20]} style={{ marginTop:"10px"} }>
                    {!this.state.SelectedPratica.InAssegnazione
                        ?
                        <><Col xl={4} xs={12}>
                            <TileCard icon={consultant} highlighted={false} onClick={() => this.onReferenteClick()}>Specialista mutui</TileCard>
                        </Col>
                        <Col xl={4} xs={12}>
                            <TileCard icon={status} highlighted={pratica.StatoHighlighted} onClick={() => this.onTimelineClick()}>Stato della richiesta</TileCard>
                        </Col>
                        {this.renderAllegaDocumenti()}
                        </>
                        :<></>}
                    
                    <Col xl={4} xs={12}>
                        <TileCard icon={guide} highlighted={false} onClick={() => this.onGuidaMutuoClick()}>Guida al mutuo</TileCard>
                    </Col>
                    {this.renderPnv()}
                </Row>
            </>
        )
    }
}

export const PageHome = withRouter(PageHomeComp)