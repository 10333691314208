import { injectable } from "inversify"

import * as Api from "../constants/ApiUrlConstants"
import container from "../wires/Bootstrapper"
import SERVICE_IDENTIFIER from "../wires/Identifiers"

import { IApiService } from "./shared/ApiService"

import {  PnvDocumentiResponseModel } from "../model/DocumentiModels"

export interface IPnvDocumentiService {
    GetDocumenti(idpratica: number): Promise<PnvDocumentiResponseModel>
}

@injectable()
export class PnvDocumentiService implements IPnvDocumentiService {
    apiService: IApiService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
    }
    async GetDocumenti(idpratica: number): Promise<PnvDocumentiResponseModel> {
        return await this.apiService.getAsync<PnvDocumentiResponseModel>(Api.GetDocumentiPnv, { idpratica })
    }
}