const SERVICE_IDENTIFIER = {
    TOOLBAR: Symbol.for("Toolbar"),
    TEST: Symbol.for("Test"),
    MENU_SERVICE: Symbol.for("MenuService"),
    NOTIFY: Symbol.for("Notify"),
    API_SERVICE: Symbol.for("ApiService"),
    APPLAYOUT_SERVICE: Symbol.for("AppLayout"),
    AUTH_SERVICE: Symbol.for("AuthService"),
    STORE: Symbol.for("Store"),
    HEADER: Symbol.for("Head"),
    HEADER_ACTION: Symbol.for("Head_Action"),
    API_LOADER_ACTION: Symbol.for("ApiLoader_Action"),
    STIPULATI_SERVICE: Symbol.for("StipulatiService"),
    ERROR_HANDLER_ACTION: Symbol.for("ErrorHandler_Action"),
    USER_SERVICE: Symbol.for("UserService"),
    USER_SEARCH_SERVICE: Symbol.for("UserSearchService"),

    AUTHENTICATION_SERVICE: Symbol.for("AuthenticationService"),
    PRATICA_SERVICE: Symbol.for("PraticaService"),
    TRACE_SERVICE: Symbol.for("TraceService"),
    DOCUMENTI_SERVICE: Symbol.for("DocumentiService"),
    FILE_PROVIDER_SERVICE: Symbol.for("FileProviderService"),
    PNVDOCUMENTI_SERVICE: Symbol.for("PNVDocumentiService"),
}

export default SERVICE_IDENTIFIER
