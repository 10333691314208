import * as React from 'react'
import { Card, Col, Divider, Row } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'

import { IPraticaService } from '../../services/PraticaService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { PraticaRouteParams } from '../PraticaRouteParams'
import { PraticaModel } from '../../model/PraticaModels'

import { NavigationBar } from '../NavigationBar'
import { CardSection } from '../CardSection'
import { ListItemHoriz, ListItemVert } from '../ListItem'
import { formatEuro } from '../shared/CurrencyFormat'

class PageDettaglioState extends PraticaModel {

}

export class PageDettaglio extends React.Component<RouteComponentProps<PraticaRouteParams>, PageDettaglioState> {

    service: IPraticaService

    constructor(props) {
        super(props)
        this.state = new PageDettaglioState()
        this.service = container.get(SERVICE_IDENTIFIER.PRATICA_SERVICE)
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const id = this.props.match.params.id
        const resp = await this.service.GetPratica(id)
        if (resp.IsValid === true)
            this.setState(resp)
    }

    renderSoggetti = () => {
        if (!this.state.Soggetti) return
        return this.state.Soggetti.map(e => <ListItemVert>{e.Nominativo}</ListItemVert>)
    }

    render() {
        return (
            <>
                <NavigationBar title="Dettaglio richiesta" />
                <Row justify="center">
                    <Col xl={8} xs={24}>
                        <Card>
                            <div style={{ margin: '0 12px' }}>
                                <span className="Bold20Credem400">{this.state.Prodotto}</span><br />
                                <span className="Regular15Grey800">Richiesta n. {this.state.IdPratica}</span>
                            </div>
                            <br />

                            <CardSection title="Caratteristiche mutuo">
                                <ListItemHoriz title="Importo richiesto">{formatEuro(this.state.Importo)}</ListItemHoriz>
                                <ListItemHoriz title="Durata">{this.state.Durata}</ListItemHoriz>
                            </CardSection>
                            <br />

                            <CardSection title="Intestazione">
                                {this.renderSoggetti()}
                            </CardSection>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}