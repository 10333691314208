import { injectable } from "inversify"

import * as Api from "../constants/ApiUrlConstants"

export interface IFileProviderService {
    DownloadFile(code: string)
    OpenFile(code: string)
}

@injectable()
export class FileProviderService implements IFileProviderService {

    DownloadFile(code: string) {
        window.open(`${Api.GetFile}?Code=${code}`)
    }

    OpenFile(code: string) {
        window.open(`${Api.GetFilePreview}?Code=${code}`)
    }
}