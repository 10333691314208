import { injectable } from "inversify"

import * as Api from "../constants/ApiUrlConstants"
import { LoginModel } from "../model/LoginModel"
import container from "../wires/Bootstrapper"
import SERVICE_IDENTIFIER from "../wires/Identifiers"
import { IAuthService } from "./security/AuthServiceTs"

import { IApiService } from "./shared/ApiService"
import { ITraceService } from "./TraceService"

export interface IAuthenticationService {
    Login(UserName: string, Password: string): Promise<any>
    RequestPassword(UserName: string): Promise<any>
    Logout(token: string): Promise<any>
}

@injectable()
export class AuthenticationService implements IAuthenticationService {

    apiService: IApiService
    authService: IAuthService
    traceService: ITraceService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
        this.traceService = container.get(SERVICE_IDENTIFIER.TRACE_SERVICE)
    }

    async Login(UserName: string, Password: string): Promise<any> {
        var resp = await this.apiService.postAsync<any>(Api.CheckPassword, { UserName, Password })
        if (resp.IsValid == true) {
            let model = new LoginModel()
            model.UserName = UserName
            model.Token = resp.AuthToken
            model.Refreshtoken = resp.RefreshToken
            this.authService.signIn(model, () => {})

            await this.traceService.TraceAccess()

            this.authService.restart();
        }

        return resp
    }

    async RequestPassword(UserName: string): Promise<any> {
        return await this.apiService.postAsync<any>(Api.RequestPassword, { UserName })
    }

    async Logout(token: string): Promise<any> {
        return await this.apiService.getAsync<any>(Api.Logout, { token})
    }
}