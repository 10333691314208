import * as React from 'react'
import { Col, Layout, Row } from 'antd'
import { Route } from 'react-router'

import moment from 'moment'
import 'moment/locale/it'

import './styles/antd-theme.less'
import './styles/custom.less'
import './styles/colors.less'
import './styles/fonts.css'
import './styles/typography.less'

import { IAuthService } from './services/security/AuthServiceTs'

import container from './wires/Bootstrapper'
import SERVICE_IDENTIFIER from './wires/Identifiers'

//import { AppContext } from './components/AppContext'
import { AppHeader } from './components/AppHeader'
import { AppRoutes } from './components/AppRoutes'

import { PageLogin } from './components/login/PageLogin'

const { Content, Footer } = Layout


moment.locale('it')

export default class App extends React.Component {
    //static displayName = App.name;

    authService: IAuthService

    constructor(props) {
        super(props)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
    }


    renderUnauthenthicated = () => {
        return (
            <Layout >
                <Content>
                    <Route path="/" component={() => <PageLogin />} />
                </Content>
            </Layout>
        )
    }

    renderAuthenticated = () => {
        return (
            <Layout className="full-height">
                <Layout>
                    <AppHeader />
                    <Layout className="tt-layout">
                        <Content className="tt-content">
                            <Row>
                                <Col lg={{ offset: 4, span: 16 }}  xs={{  span: 24 }} >
                                    <AppRoutes />
                                </Col>
                            </Row>
                        </Content>
                        <Footer />
                    </Layout>
                </Layout>
            </Layout>
        )
    }

    render() {
        return this.authService.isAuthenticated() == false
            ? this.renderUnauthenthicated()
            : this.renderAuthenticated()
    }
}
