import * as React from 'react'
import { Col, Divider, Row } from 'antd'

interface ListItemProps {
    title?: string
}

export const ListItemHoriz: React.FC<ListItemProps> = (props) => {
    return (
        <>
            <Row justify="space-between">
                <Col><span className="Regular13Grey800">{props.title}</span><br /></Col>
                <Col><span className="Regular15Grey800">{props.children}</span></Col>
            </Row>
            <Divider style={{ margin: '12px 0' }} />
        </>
    )
}

export const ListItemVert: React.FC<ListItemProps> = (props) => {
    const title = props.title
        ? <><span className="Regular13Grey800">{props.title}</span><br /></>
        : <></>

    return (
        <>
            {title}
            <span className="Regular15Grey800">{props.children}</span>
            <Divider style={{ margin: '12px 0' }} />
        </>
    )
}