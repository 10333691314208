import "reflect-metadata";
import { Container } from "inversify";
import { IApiService, ApiService } from "../services/shared/ApiService";
import SERVICE_IDENTIFIER from "./Identifiers";
import { INotifyService, NotifyService } from "../services/shared/NotifyService";
import { IAuthService, AuthService } from "../services/security/AuthServiceTs";
import { IPraticaService, PraticaService } from "../services/PraticaService";
import { IAuthenticationService, AuthenticationService } from "../services/AuthenticationService";
import { ITraceService, TraceService } from "../services/TraceService";
import { IDocumentiService, DocumentiService } from "../services/DocumentiService";
import { IFileProviderService, FileProviderService } from "../services/FileProviderService";
import { IPnvDocumentiService, PnvDocumentiService } from "../services/PnvDocumentiService";

let IoCContainer = new Container();
IoCContainer.bind<IApiService>(SERVICE_IDENTIFIER.API_SERVICE).to(ApiService);
IoCContainer.bind<INotifyService>(SERVICE_IDENTIFIER.NOTIFY).to(NotifyService);
IoCContainer.bind<IAuthService>(SERVICE_IDENTIFIER.AUTH_SERVICE).to(AuthService);

IoCContainer.bind<IPraticaService>(SERVICE_IDENTIFIER.PRATICA_SERVICE).to(PraticaService);
IoCContainer.bind<IAuthenticationService>(SERVICE_IDENTIFIER.AUTHENTICATION_SERVICE).to(AuthenticationService);
IoCContainer.bind<ITraceService>(SERVICE_IDENTIFIER.TRACE_SERVICE).to(TraceService);
IoCContainer.bind<IDocumentiService>(SERVICE_IDENTIFIER.DOCUMENTI_SERVICE).to(DocumentiService);
IoCContainer.bind<IPnvDocumentiService>(SERVICE_IDENTIFIER.PNVDOCUMENTI_SERVICE).to(PnvDocumentiService); 
IoCContainer.bind<IFileProviderService>(SERVICE_IDENTIFIER.FILE_PROVIDER_SERVICE).to(FileProviderService);


export default IoCContainer;