import { serialize, Options } from 'object-to-formdata'

const ttoptions = {
    /**
     * include array indices in FormData keys
     * defaults to false
     */
    indices: true
}

export const serializer = <T>(object: T, options?: Options, existingFormData?: FormData, keyPrefix?: string): FormData => {
    let allOptions = { ...ttoptions, ...options }
    return serialize(object, allOptions, existingFormData, keyPrefix)
}