import * as React from 'react'
import { Card, Col, Divider, Row } from 'antd'
import { RouteComponentProps } from 'react-router'

import { IPraticaService } from '../../services/PraticaService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { PraticaRouteParams } from '../PraticaRouteParams'
import { ReferenteModel } from '../../model/PraticaModels'

import { NavigationBar } from '../NavigationBar'
import { CardSection } from '../CardSection'
import { ListItemVert } from '../ListItem'

import profile from '../../content/profile_64.svg'

class PageReferenteState extends ReferenteModel {

}

export class PageReferente extends React.Component<RouteComponentProps<PraticaRouteParams>, PageReferenteState> {

    service: IPraticaService

    constructor(props) {
        super(props)
        this.state = new PageReferenteState()
        this.service = container.get(SERVICE_IDENTIFIER.PRATICA_SERVICE)
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const id = this.props.match.params.id
        const resp = await this.service.GetReferente(id)
        if (resp.IsValid === true)
            this.setState(resp)
    }

    onChiamaClick = () => {
        window.open(`tel:${this.state.Telefono}`)
    }

    render() {
        return (
            <>
                <NavigationBar title="Specialista mutui" />
                <Row justify="center">
                    <Col xl={8} xs={24}>
                        <Card>
                            <Row justify="space-around">
                                <Col><img src={profile} /></Col>
                            </Row>
                            <Row justify="space-around">
                                <Col><span className="Regular15Grey800">{this.state.Referente}</span></Col>
                            </Row>
                            <br />
                            <br />

                            <CardSection title="Contatto telefonico">
                                <ListItemVert title="Cellulare">{this.state.Telefono}</ListItemVert>
                            </CardSection>

                            <br />
                            <br />
                            <Row justify="space-between">
                                <Col></Col>
                                <Col><a className="Bold20Credem400" onClick={() => this.onChiamaClick()}>Chiama</a></Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}