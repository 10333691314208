export class ModelBase {
  public Notifies: Array<Notify>;
  public Events: Array<Notify>;
  public Id: string;
  public Code: string;
  public IsValid: boolean;
}

export class Notify {
  public Message: string;
  public ServerMessage: string;
  public Code: string;
  public Type: NotifyType;
}

export enum NotifyType {
  Error = 0,
  Warning = 1,
  Info = 2
}