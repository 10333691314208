import { injectable } from "inversify"

import * as Api from "../constants/ApiUrlConstants"
import container from "../wires/Bootstrapper"
import SERVICE_IDENTIFIER from "../wires/Identifiers"

import { IApiService } from "./shared/ApiService"
import { IFileProviderService } from "./FileProviderService"

import { PraticaModel, PraticaMutuoTimelineResponseModel, PraticheModel, ReferenteModel } from "../model/PraticaModels"
import { ModelBase } from "../model/BaseModels"

export interface IPraticaService {
    GetPratiche(): Promise<PraticheModel>
    GetPratica(PraticaId: string): Promise<PraticaModel>
    GetReferente(PraticaId: string): Promise<ReferenteModel>
    GetPraticaTimeline(PraticaId: string): Promise<PraticaMutuoTimelineResponseModel>
    GetDocumento(Id: string, PraticaId: string, TipoDocumento: string)
    GetPnvDocumento(riferimentoId: string) 
}

@injectable()
export class PraticaService implements IPraticaService {
    apiService: IApiService
    fileProviderService: IFileProviderService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.fileProviderService = container.get(SERVICE_IDENTIFIER.FILE_PROVIDER_SERVICE)
    }

    async GetPratiche(): Promise<PraticheModel> {
        return await this.apiService.getAsync<PraticheModel>(Api.GetPratiche, null)
    }

    async GetPratica(PraticaId: string): Promise<PraticaModel> {
        return await this.apiService.getAsync<PraticaModel>(Api.GetPratica, { PraticaId })
    }

    async GetReferente(PraticaId: string): Promise<ReferenteModel> {
        return await this.apiService.getAsync<ReferenteModel>(Api.GetReferente, { PraticaId })
    }

    async GetPraticaTimeline(PraticaId: string): Promise<PraticaMutuoTimelineResponseModel> {
        return await this.apiService.getAsync<any>(Api.GetPraticaTimeline, { PraticaId })
    }

    async GetDocumento(Id: string, PraticaId: string, TipoDocumento: string) {
        let resp = await this.apiService.getAsync<ModelBase>(Api.GetDocumentoCode, { Id, PraticaId, TipoDocumento })
        if (resp.IsValid == true)
            this.fileProviderService.OpenFile(resp.Code)
    }
    async GetPnvDocumento(riferimentoId: string) {
        let resp = await this.apiService.getAsync<ModelBase>(Api.GetPnvDocumentoCode, { idRiferimento :riferimentoId })
        if (resp.IsValid == true)
            this.fileProviderService.OpenFile(resp.Code)
    }
}