import { ModelBase } from './BaseModels'

export class DocumentoModel {
    Id: string
    TipoDocumento: string
    Descrizione: string
}

export class PraticheModel extends ModelBase {
    Pratiche: Array<PraticaItemModel>
}

export class PraticaItemModel {
    Id: string
    Importo: number
    Durata: string
    Tasso: string
    InAssegnazione

    StatoHighlighted: boolean

    CaricaDocumentiHighlighted: boolean
    CaricaDocumentiVisible: boolean

    ElencoDocumentiVisible: boolean
    ElencoDocumentiHighlighted: boolean

    ElencoPnvVisible: boolean
    ElencoPnvHighlighted: boolean

    HasPendingPersonali :boolean
    HasPendingImmobili : boolean

    Checklist: DocumentoModel
}

export class Soggetto {
    // Garante, intestatario...
    Ruolo: string
    Nominativo: string
}

export class PraticaModel extends ModelBase {
    IdPratica: string
    Prodotto: string
    Importo: number
    Durata: string
    Tasso: string
    Soggetti: Array<Soggetto>
}

export class ReferenteModel extends ModelBase {
    Referente: string
    Telefono: string
}

export class PraticaMutuoTimelineResponseModel extends ModelBase {
    Timeline: Array<PraticaMutuoTimelineItemModel>
}

export class PraticaMutuoTimelineItemModel {
    NumeroPassaggio: number
    Titolo: string
    Descrizione: string
    Data: Date
    StatoPassato: boolean
    StatoCorrente: boolean
    Codice: string
}