import * as React from 'react'
import { Route } from 'react-router'

import { PageHome } from './home/PageHome'
import { PageCategoriaDocumenti } from './allegaDocumenti/PageCategorieDocumenti'
import { PageCaricaDocumenti } from './caricaDocumenti/PageCaricaDocumenti'
import { PageDettaglio } from './dettaglio/PageDettaglio'
import { PageReferente } from './referente/PageReferente'
import { PageTimeline } from './timeline/PageTimeline'
import { PagePnvDocumenti } from './pnvDocumenti/PagePnvDocumenti'
import { PageCaricaDocumentiAssicurativi } from './caricaDocumenti/PageCaricaDocumentiAssicurativi'


export class AppRoutes extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                <Route exact path="/" component={PageHome} />
                <Route path="/AllegaDocumenti/:id?" component={PageCategoriaDocumenti} />
                <Route path="/CaricaDocAmministrativi/:id/:tipodocumento" component={PageCaricaDocumentiAssicurativi} />
                <Route path="/CaricaDoc/:id/:tipodocumento" component={PageCaricaDocumenti} />
                <Route path="/Dettaglio/:id" component={PageDettaglio} />
                <Route path="/Referente/:id" component={PageReferente} />
                <Route path="/Timeline/:id" component={PageTimeline} />
                <Route path="/PnvDocumenti/:id" component={PagePnvDocumenti} />
            </>
        )
    }
}