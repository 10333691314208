import { ModelBase } from "./BaseModels"
import { PnvDocumentiModel } from "./PnvDocumentiModel"

export enum AllDocType {
    DaCaricare,
    Caricati,
    Inviati,    
    Verificato
}

export enum TipoDocumento {
    personali, immobile, assicurativo,ape
}

export enum DocumentState {
    Invalido,
    Downloadabile,//file pronto per essere scaricato,
    //conosciuti da app
    DaCaricare, //nessun file presente
    Caricato, //almeno un file presente
    DaVerificare, //inviato a crif
    Verificato, //ok crif
    DaInviare,
    Uploadato
}

export class Documento
{
    IdDocumento: string
    Descrizione: string
    Stato: DocumentState    
    CategoryId: string
    CategoryTitle: string
    Note: string
}

export class DocumentiResponseModel extends ModelBase
{
    //lista documenti
    //ogni doc ha la categoria per poter permettere alla ui di ragruppare per tipo
    Documenti: Array<Documento>

    //stato complessivo dei doc, mi dice se sono stati tutti caricati/verificati ecc
    StatoDocumenti: AllDocType

    IsLoaded: boolean

    NomeDoc: string

    isNewDocVisible: boolean 

    isSending:boolean = false
}

export class PnvDocumentiResponseModel extends ModelBase {
    //lista documenti
    //ogni doc ha la categoria per poter permettere alla ui di ragruppare per tipo
    Documenti: Array<PnvDocumentiModel>

}

export class DocumentiInput {
    TipoDocumento: string
    idPratica: string
}

export class DocumentiUploadInput {
    idDocumento: string
    tipodocumento: string
    idPratica: string
    files: any[]
}
export class DocumentiNewUploadInput {
    nomeDocumento: string
    tipodocumento: string
    idPratica: string
    files: any[]
}

export class DocumentoDeleteInput {
    idDocumento: string
    IdPratica: string
    TipoDocumento: TipoDocumento
}


export class TipoDocumentoModel extends ModelBase
{
    Description: string
    Count: number
    Total: number
    Tipo: TipoDocumento
    Order: number
    State? :AllDocType
}

export class CategorieDocumentoResponseModel extends ModelBase
{
    Categorie: Array<TipoDocumentoModel>
}