import * as React from 'react'
import { Table, Button, Collapse, Upload, Result, Modal, Form, Input, Spin } from 'antd'
import { RcCustomRequestOptions } from 'antd/lib/upload/interface'
import { RouteComponentProps, withRouter } from 'react-router'

import { IDocumentiService } from '../../services/DocumentiService'
import IoCContainer from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'

import { AllDocType, DocumentiInput, DocumentiNewUploadInput, DocumentiResponseModel, DocumentiUploadInput, Documento, DocumentoDeleteInput, DocumentState, TipoDocumento } from '../../model/DocumentiModels'
import { CaricaDocumentiRouteParams } from './CaricaDocumentiRouteParams'

import { NavigationBar } from '../NavigationBar'
import successIcon from './../../content/icon_status.svg'
import infoIcon from './../../content/info_grey.svg'
import { UploadOutlined } from '@ant-design/icons';
import { Mutex } from 'async-mutex';


import './caricadocumenti.less'

const { Panel } = Collapse
const { Column } = Table

const iconInviatoStyle = {
    width: '40px', height: '40px'
}

export class GroupedItem {
    key: string
    value: Array<Documento>
}
const { confirm } = Modal;
const mutex = new Mutex();

class PageCaricaDocumentiAssicurativiCmp extends React.Component<RouteComponentProps<CaricaDocumentiRouteParams>, DocumentiResponseModel> {

    private documentiService: IDocumentiService
    private tipoDoc: TipoDocumento



    getStatoDescription = (stato: DocumentState): string => {
        switch (stato) {
            case DocumentState.Invalido: return 'Da Caricare'
            case DocumentState.Downloadabile: return 'Scaricabile'
            case DocumentState.DaCaricare: return 'Da Caricare'
            case DocumentState.Caricato: return 'Caricato'
            case DocumentState.DaVerificare: return 'Inviato'
            case DocumentState.Verificato: return 'Inviato'
            case DocumentState.DaInviare: return 'Inviato'
            default: return ""
        }
    }

    uploadNewImage = async (options: RcCustomRequestOptions, nomedoc: string) => {

        const release = await mutex.acquire();
        
        const { file } = options

        this.setState({isSending:true})

        var data = new DocumentiNewUploadInput()
        data.nomeDocumento = nomedoc
        data.files = [file]
        data.idPratica = this.props.match.params.id
        data.tipodocumento = TipoDocumento[this.tipoDoc]
        var resp = await this.documentiService.UploadNewFiles(data)
        this.setState({ isNewDocVisible: false, isSending:false })
        if (resp.IsValid === true) {
            this.caricaDocs();
        }

        release();
        
    };

    deleteImage = async (iddoc: string) => {

        var data = new DocumentoDeleteInput()
        data.idDocumento = iddoc
        data.IdPratica = this.props.match.params.id
        data.TipoDocumento = this.tipoDoc

        var resp = await this.documentiService.DeleteFile(data)
        if (resp.IsValid === true)
            this.caricaDocs()
    };

    groupBy = (list, keyGetter) => {
        const map = new Map()
        list.forEach((item) => {
            const key = keyGetter(item)
            const collection = map.get(key)
            if (!collection) {
                map.set(key, [item])
            } else {
                collection.push(item)
            }
        })
        return map
    }

    inviaDoc = async () => {
        var data = new DocumentiInput()
        data.idPratica = this.props.match.params.id
        data.TipoDocumento = TipoDocumento[this.tipoDoc]


        var resp = await this.documentiService.InviaDocumenti(data)
        if (resp.IsValid === true)
            this.caricaDocs();

        //confirm({
        //    title: 'Dichiarazione di Responsabilità',
        //    content: <>Dichiaro di essere autorizzato a caricare i documenti richiesti anche per conto degli altri interessati alla richiesta di mutuo.<br />
        //             In assenza di autorizzazione la tua richiesta non potrà essere presa in carico</>,
        //    onOk: async ()=> {
        //        var resp = await this.documentiService.InviaDocumenti(data)
        //        if (resp.IsValid === true)
        //            this.caricaDocs();
        //    },
        //    onCancel() {
        //        console.log('Cancel');
        //    },
        //});


        
    }

    nuovoDocumento = () => {
        debugger
        this.setState({ isNewDocVisible:true})
    }


    constructor(props) {
        super(props)
        var initialstate = new DocumentiResponseModel();
        initialstate.IsLoaded = false;
        this.state = initialstate;

        this.documentiService = IoCContainer.get(SERVICE_IDENTIFIER.DOCUMENTI_SERVICE)
        this.tipoDoc = TipoDocumento[this.props.match.params.tipodocumento]
    }

    async componentDidMount() {
        this.caricaDocs();
    }

    async caricaDocs() {
        let data = new DocumentiInput()
        data.idPratica = this.props.match.params.id
        data.TipoDocumento = TipoDocumento[this.tipoDoc]

        debugger

        var res = await this.documentiService.GetDocumentiAssicurativi(data)
        if (res.IsValid === true)
            this.setState({ ...res, IsLoaded: true });
    }

    renderDescrizione = (row: Documento) => {
        if (!row.Note) return row.Descrizione
        return <>
            <p>{row.Descrizione}</p>
            <div style={{ borderLeft: '2px solid red', height: '100%', paddingLeft: '12px' }}>
                <span className="Regular14Grey700">{row.Note}</span>
            </div>
        </>
    }

    renderStatoAction = (row: Documento) => {
        switch (row.Stato) {
            case DocumentState.Invalido:
            case DocumentState.Caricato:
                return <Button type='primary' ghost shape='round' onClick={() => this.deleteImage(row.IdDocumento)}>Elimina</Button>
            default:
                return <></>
        }
    }


    renderdocs = ()  => {
        return (
            <Table dataSource={this.state.Documenti} size="middle" rowKey={it => it.IdDocumento} pagination={false}>
                <Column title="Tipo documento" dataIndex="Descrizione" render={(_, row: Documento) => this.renderDescrizione(row)} />
                <Column title="Stato" dataIndex="Stato" render={(_, row: Documento) => this.getStatoDescription(row.Stato)}/>
                <Column title="" dataIndex="Id" render={(_, row: Documento) => this.renderStatoAction(row) } />
            </Table>
        )
    }

    renderPageTitle = () => {
        return <span>{`Allega Documenti > Assicurativi`}  <a onClick={() => this.onHelpClick()}><img src={infoIcon} style={{ width: '18px' }} /></a></span> 
    }


    onHelpClick = () => {
        Modal.info({
            width: 800,
            title: "",
            content: <>Carica qui i documenti assicurativi riferiti all'operazione (polizza obbligatoria scoppio e incendio di terzi, polizza donazione, vincolo ed eventuali altri documenti assicurativi)</>
        })
    }

    render() {
        debugger
        const hasDocuments = this.state.Documenti != null && this.state.Documenti.length > 0
        if (this.state.IsLoaded == false) return <></>;

        //if(this.state.StatoDocumenti === AllDocType.Inviati && hasDocuments === true)
        //    return (<>
        //        <NavigationBar title={`Allega Documenti > Assicurativi`} />
        //        <Result
        //        icon={<img src={successIcon} style={iconInviatoStyle} />}
        //        title={<span className='Medium18PlacidGreen'>Documenti inviati con successo!</span>}
        //            subTitle={<span className="Regular14Grey800">La procedura di invio documenti è in elaborazione, a breve potrai caricare ulteriori documenti</span>} />
        //        </>)

        return (
            <div>
                <NavigationBar title={this.renderPageTitle() } />

                {(hasDocuments === true )
                    ? this.renderdocs()
                    : <Result
                        icon={<img src={infoIcon} style={iconInviatoStyle} />}
                        title={<span className='Medium18PlacidGreen'>Documenti assicurativi, premi Nuovo Documento per creare aggiungere file</span>} /> 
                }
                <Button type='primary' shape='round'
                    style={{ float: 'right', marginTop: '20px', width: '250px' }}
                    onClick={() => this.nuovoDocumento()}>Nuovo Documento </Button>
                
                {(hasDocuments === true)
                    ? <Button type='primary' shape='round' disabled={this.state.StatoDocumenti != AllDocType.Caricati}
                        style={{ float: 'right', marginTop: '20px', width: '250px' }}
                        onClick={() => this.inviaDoc()}>Invia </Button>
                    : <></>}
                
                <Modal
                    title="Nuovo Documento"
                    visible={this.state.isNewDocVisible}
                    footer={null}
                    //onOk={() => this.setState({ isNewDocVisible: false })}
                    onCancel={() => this.setState({ isNewDocVisible: false })}
                >
                    <Spin spinning={this.state.isSending} >
                        <Form layout='horizontal'>
                            <Form.Item label="Nome Documento">
                                <Input name="nomedoc" value={this.state.NomeDoc} 
                                    onChange={e => this.setState({ NomeDoc: e.target.value })} />
                            </Form.Item>
                        </Form>
                        <Upload
                            accept="*/*"
                            multiple
                            showUploadList={false}
                            customRequest={options => this.uploadNewImage(options, this.state.NomeDoc)}
                        >
                            <Button icon={<UploadOutlined />}>Seleziona Documento</Button>
                        </Upload>
                     </Spin>
                </Modal >
            </div >
        )
    }
}

export const PageCaricaDocumentiAssicurativi = withRouter(PageCaricaDocumentiAssicurativiCmp)
