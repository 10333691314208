import * as React from 'react'
import { Layout, Menu, Col, Row } from 'antd'

import SERVICE_IDENTIFIER from '../wires/Identifiers'
import container from '../wires/Bootstrapper'

import { IAuthService } from './../services/security/AuthServiceTs'

import credem from '../content/Credem_Banca_CMYK.svg'
import logout from '../content/logout.svg'
import { IAuthenticationService } from '../services/AuthenticationService'

const { Header } = Layout

export class AppHeader extends React.Component<any, any> {

    authService: IAuthService
    authenticationService: IAuthenticationService 

    constructor(props) {
        super(props)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
        this.authenticationService = container.get(SERVICE_IDENTIFIER.AUTHENTICATION_SERVICE);
    }

    onLogoutClick = () => {
        debugger
        this.authenticationService.Logout(this.authService.getRefresh());
        this.authService.signOutAndReload()
    }

    render() {
        return (
            <Header>
                <Row justify="space-between" align="middle">
                    <Col></Col>
                    <Col><img src={credem} height="56px" /></Col>
                    <Col><a onClick={() => this.onLogoutClick()}><img src={logout} /></a></Col>
                </Row>
            </Header>
        )
    }
}