import { injectable } from "inversify"

import * as Api from "../constants/ApiUrlConstants"
import container from "../wires/Bootstrapper"
import SERVICE_IDENTIFIER from "../wires/Identifiers"

import { IApiService } from "./shared/ApiService"

import { CategorieDocumentoResponseModel, DocumentiInput, DocumentiNewUploadInput, DocumentiResponseModel, DocumentiUploadInput, DocumentoDeleteInput } from "../model/DocumentiModels"
import { serializer } from "./shared/FormDataSerializer"

export interface IDocumentiService {
    GetCategorieDocumenti(idPratica: string): Promise<CategorieDocumentoResponseModel>
    GetDocumenti(data: DocumentiInput): Promise<DocumentiResponseModel>
    GetDocumentiAssicurativi(data: DocumentiInput): Promise<DocumentiResponseModel>
    UploadFiles(data: DocumentiUploadInput): Promise<DocumentiResponseModel>
    UploadNewFiles(data: DocumentiNewUploadInput): Promise<DocumentiResponseModel>
    DeleteFile(data: DocumentoDeleteInput): Promise<DocumentiResponseModel>
    InviaDocumenti(data: DocumentiInput): Promise<DocumentiResponseModel>
}

@injectable()
export class DocumentiService implements IDocumentiService {
    apiService: IApiService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
    }

    async GetCategorieDocumenti(idPratica: string): Promise<CategorieDocumentoResponseModel> {
        return await this.apiService.getAsync<CategorieDocumentoResponseModel>(Api.GetCategorieDocumenti, { idPratica })
    }

    async GetDocumenti(data: DocumentiInput): Promise<DocumentiResponseModel> {
        return await this.apiService.getAsync<DocumentiResponseModel>(Api.GetDocumenti, data)
    }

    async GetDocumentiAssicurativi(data: DocumentiInput): Promise<DocumentiResponseModel> {
        return await this.apiService.getAsync<DocumentiResponseModel>(Api.GetDocumentiAssicurativi, data)
    }

    // Quando passi di qui, controlla se il bug https://github.com/therealparmesh/object-to-formdata/issues/93 è stato risolto.
    // In tal caso, seguendo la risoluzione, è possibile eliminare la conversione manuale in form data della lista dei file
    private ConvertDocuments(data: DocumentiUploadInput): FormData {
        let formData = new FormData()
        if (data.files)
            data.files.forEach(e => formData.append('files', e))

        return formData
    }

    private ConvertNewDocuments(data: DocumentiNewUploadInput): FormData {
        let formData = new FormData()
        if (data.files)
            data.files.forEach(e => formData.append('files', e))

        return formData
    }

    async UploadFiles(data: DocumentiUploadInput): Promise<DocumentiResponseModel> {
        let preFormData = this.ConvertDocuments(data)
        const formData = serializer(data, null, preFormData)
        return await this.apiService.postAsync<DocumentiResponseModel>(Api.UploadFiles, formData)
    }


    async UploadNewFiles(data: DocumentiNewUploadInput): Promise<DocumentiResponseModel> {
        let preFormData = this.ConvertNewDocuments(data)
        const formData = serializer(data, null, preFormData)
        return await this.apiService.postAsync<DocumentiResponseModel>(Api.UploadNewFiles, formData)
    }

    async DeleteFile(data: DocumentoDeleteInput): Promise<DocumentiResponseModel> {
        return await this.apiService.getAsync<DocumentiResponseModel>(Api.DeleteFile, data)
    }

    async InviaDocumenti(data: DocumentiInput): Promise<DocumentiResponseModel> {
        return await this.apiService.getAsync<DocumentiResponseModel>(Api.InviaDocumenti, data)
    }
}